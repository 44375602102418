import { Box, Typography } from '@material-ui/core'
import { GlobalConfiguration, InfoBoxItem } from 'graphql/types'
import { useTranslation } from 'react-i18next'

interface InfoBoxProps {
    globalConfiguration: GlobalConfiguration
    currentPath: string
    backgroundColor: string
    data?: Record<string, string | number | undefined>
}

const InfoBox = ({ globalConfiguration, currentPath, backgroundColor, data }: InfoBoxProps): JSX.Element | null => {
    const { t } = useTranslation()

    const relevantConfig = globalConfiguration.infoBox?.find((config): config is InfoBoxItem => {
        if (config && config.path === '/') {
            return currentPath === '/'
        }
        return config !== null && currentPath.includes(config.path)
    })

    if (relevantConfig) {
        const message = t(relevantConfig.messageKey, data)

        return (
            <Box
                width={1}
                height={1}
                marginTop={3}
                flexDirection="column"
                display="flex"
                style={{
                    backgroundColor: backgroundColor,
                    borderRadius: '5px',
                    alignSelf: 'flex-end',
                }}
            >
                <Typography
                    variant="h5"
                    align="left"
                    dangerouslySetInnerHTML={{ __html: message }}
                    style={{ padding: '20px' }}
                ></Typography>
            </Box>
        )
    } else {
        return null
    }
}

export default InfoBox
