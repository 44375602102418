import { Box, ButtonBase, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import colors from 'theme/colors'
import TestIDs from 'utils/TestIDs'

export interface ProductCategoryCardProps {
    description?: string | null
    id: string
    onClick: (id: string) => void
    priceText?: string
    selected: boolean
    subTitle?: string | null
    title: string
}

const ProductCategoryCard = (props: ProductCategoryCardProps): JSX.Element => {
    const { description, id, onClick, priceText, selected, subTitle, title } = props
    const borderProp: string = selected
        ? `2px solid ${colors.productCategoryCardBorderColor}`
        : `2px solid ${colors.midGray}`
    const borderRadius = 5

    return (
        <ButtonBase
            onClick={(): void => onClick(id)}
            {...TestIDs.GET_PRODUCT_CATEGORIES_SELECTION('PRODUCT_CATEGORY_CARD', id)}
            className={selected ? clsx('Selected', 'ProductCard') : 'ProductCard'}
            style={{
                boxShadow: `1px 2px 2px ${colors.lightGray}`,
                borderRadius: borderRadius,
            }}
        >
            <Grid container direction={'column'} key={id} className={selected ? 'Selected' : ''} style={{}}>
                <Grid item className={'ProductCardTitleContainer'} style={{}}>
                    <Box
                        bgcolor={selected ? colors.productCategoryCardHeadBGColor : colors.lightGray}
                        paddingLeft={2}
                        borderTop={borderProp}
                        borderLeft={borderProp}
                        borderRight={borderProp}
                        style={{
                            borderTopLeftRadius: borderRadius,
                            borderTopRightRadius: borderRadius,
                        }}
                    >
                        <Box bgcolor={colors.white}>
                            <img
                                src={process.env.PUBLIC_URL + '/customize/checkboxSelected.svg'}
                                alt={'✓'}
                                style={{ opacity: selected ? 1 : 0, transition: '250ms all' }}
                            />
                        </Box>
                    </Box>
                </Grid>
                <Grid item className={'ProductCardContentContainer'}>
                    <Box
                        paddingTop={1}
                        paddingBottom={3}
                        paddingLeft={2}
                        paddingRight={2}
                        bgcolor={selected ? colors.productCategoryCardBGColor : colors.white}
                        textAlign={'center'}
                        borderBottom={borderProp}
                        borderLeft={borderProp}
                        borderRight={borderProp}
                        style={{
                            borderBottomRightRadius: borderRadius,
                            borderBottomLeftRadius: borderRadius,
                        }}
                    >
                        <Grid
                            container
                            direction={'column'}
                            justifyContent={'space-between'}
                            className={selected ? clsx('Selected', 'ProductCard') : 'ProductCard'}
                        >
                            <Grid container item direction={'column'} spacing={1}>
                                <Grid item>
                                    <Typography variant={'h2'}>{title}</Typography>
                                </Grid>
                                {subTitle && (
                                    <Grid item>
                                        <Typography variant={'subtitle1'}>{subTitle}</Typography>
                                    </Grid>
                                )}
                                {description && (
                                    <Grid item>
                                        <Typography
                                            variant={'subtitle1'}
                                            className={'ProductCategoryCardDangerously'}
                                            dangerouslySetInnerHTML={{ __html: description }}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                            {priceText && (
                                <Grid container item direction={'column'}>
                                    <Typography
                                        className={'ProductCategoryCardDangerously'}
                                        dangerouslySetInnerHTML={{ __html: priceText }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </ButtonBase>
    )
}

export default ProductCategoryCard
