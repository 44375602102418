const colors = {
    black: '#000000',
    color1: '#222222',
    color2: '#3FAEE1',
    color3: '#CFA22E',
    darkGray: '#929292',
    basketGray: '#909090',
    darkerGray: '#737373',
    green: '#0580B8',
    blue: '#0580B8',
    lightBlue: '#008ACC',
    lightGreen: 'rgba(0, 204, 106, 0.5)',
    header: '#F8F8F8',
    lightGray: '#F3F0F0',
    midGray: '#C4C4C4',
    warning: '#EB5757',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    white: '#FFFFFF',
    basketInfoIcon: 'rgba(34, 34, 34, 0.54)',
    buttonPrimaryColor: '#222222',
    optionItemMainColor: '#3FAEE1',
    optionItemBorderColor: '#3FAEE1',
    optionItemBackgroundColor: '#3FAEE1',
    optionItemFontColor: '#FFFFFF',
    productCategoryCardBorderColor: '#3FAEE1',
    productCategoryCardHeadBGColor: '#3FAEE1',
    productCategoryCardBGColor: '#3FAEE1',
    productCategoryCardFontColor: '#FFFFFF',
    configurationItemBackgroundColorHead: 'transparent',
    configurationItemBackgroundColorCard: '#3FAEE1',
    configurationItemTextColor: '#FFFFFF',
    configurationItemBorderColor: '#3FAEE1',
    orderVerificationEdit: '#929292',
}

export default colors
